// src/components/_common/StructuredData/index.jsx
import React from "react";
import Head from 'next/head';
import PropTypes from 'prop-types';

import debugLog from '@/helpers/debugLog';

export default function StructuredData({ data }) {
  debugLog('StructuredData', 'debug', data);
  return (
    <Head>
      <script
        key="structured-data"
        type="application/ld+json"
        // dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </Head>
  );
}

// PropTypes
StructuredData.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
]),
// Definicje dla pozostałych właściwości...
};
