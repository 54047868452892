// src/components/_common/Breadcrumbs/index.js
import React, { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
// import Cookies from 'universal-cookie';
import Image from 'next/image';
// import PropTypes from 'prop-types';
import Link from 'next/link';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import styles from './css/breadcrumbs.module.css';

import { IconPaths } from '@/constants';
import { genAltText } from '@/utils';
import { useBreadcrumbs } from '@/contexts/BreadcrumbsContext';
import { BugReportModalBox } from '@/components/_common/BugReportModalBox';
import { serializeToJson } from '@/helpers/serializeToJson';

import debugLog from '@/helpers/debugLog'; 

export const Breadcrumbs = () => {
    const { breadcrumbs } = useBreadcrumbs();
    const [isBugReportModalBoxOpen, setIsBugReportModalBoxOpen] = useState(false);
    const router = useRouter();

    // Selektor Reduxa
    const sessionData = useSelector((state) => state.session.user);

    const handleBugReportModalBox = () => {
        // Sprawdź, czy użytkownik jest zalogowany
        if (!sessionData) {
            // Jeśli nie jest zalogowany, przekieruj na stronę logowania
            router.push('/zaloguj');
          }else {
            setIsBugReportModalBoxOpen(true);
          }
    }

    // 
    // application/ld+json
    // 
    // const breadcrumbSchema = {
    //     "@context": "https://schema.org",
    //     "@type": "BreadcrumbList",
    //     "itemListElement": breadcrumbs.map((breadcrumb, index) => ({
    //         "@type": "ListItem",
    //         "position": index + 1,
    //         "name": breadcrumb.title,
    //         "item": breadcrumb.href ? `${process.env.NEXT_PUBLIC_APP_URL}${breadcrumb.href}` : undefined, // Ustaw pełny URL
    //         // "item": breadcrumb.href ? `${breadcrumb.href}` : undefined, // Ustaw pełny URL
    //     })).filter(item => item.item) // Filtruj, aby usunąć elementy bez URL
    // };
    const breadcrumbSchema = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbs.map((breadcrumb, index) => {
          const item = {
            "@type": "ListItem",
            "position": index + 1,
            "name": breadcrumb.title
          };
          if (breadcrumb.href) {
            item.item = `${breadcrumb.href}`;
          }
          return item;
        }).filter(item => item.item) // Filtruj, aby usunąć elementy bez URL
    };
    const jsonString = serializeToJson(breadcrumbSchema);
    // 
    // END application/ld+json
    // 

    // debugLog
    useEffect(() => {
        debugLog('Breadcrumbs sessionData', 'debug', sessionData);
    }, [sessionData]);

    return (
        <>
            {breadcrumbs && breadcrumbs.length > 0 && (
                <>
                    <Head>
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{ __html: jsonString }}
                        />
                    </Head>
                    <div className={styles["brcbs--sec"]}>
                        <div className={styles["brcbs--con"]}>
                            <div className={styles["brcbs--l"]}>
                                <nav aria-label="breadcrumb">
                                    <ol>
                                        {
                                            breadcrumbs.map((breadcrumb, index) => {
                                                const isLast = index === breadcrumbs.length - 1;
                                                return (
                                                    <li key={breadcrumb.title + `breadcrumb-${index}`} className={styles["brcbs--btn-li"]}>
                                                        {
                                                            breadcrumb.href && !isLast ? 
                                                            (
                                                                <>
                                                                    <Link href={breadcrumb.href}>
                                                                        {breadcrumb.title}
                                                                    </Link>
                                                                    <Image src={IconPaths.arrowDropRightIcon} alt={genAltText(IconPaths.chevronArrowRoundIcon)} width={18} height={18} className={styles["brcbs--ico"]} />
                                                                </>
                                                            ) : breadcrumb.onClick && !isLast ? (
                                                                <>
                                                                    <span onClick={() => { breadcrumb.onClick();}} style={{ cursor: 'pointer' }} className={styles["brcbs--t"]}>
                                                                        {breadcrumb.title}
                                                                    </span>
                                                                    <Image src={IconPaths.arrowDropRightIcon} alt={genAltText(IconPaths.chevronArrowRoundIcon)} width={18} height={18} className={styles["brcbs--ico"]} />
                                                                </>
                                                            ) : (
                                                                <span className={styles['brcbs--active']}>{breadcrumb.title}</span>
                                                            )
                                                        }
                                                    </li>
                                                );
                                            })
                                        }
                                    </ol>
                                </nav>
                            </div>
                            <div className={styles["brcbs--r"]}>
                                <Image src={IconPaths.bugReportIcon}  alt={genAltText(IconPaths.bugReportIcon)} width={18} height={20} className={styles["brcbs--ico"]} />
                                <div className={styles["brcbs--t"]} onClick={() => handleBugReportModalBox()} style={{ cursor: 'pointer', color: '#40466a' }}>
                                    <FormattedMessage
                                        id = "BugReport"
                                        defaultMessage="Zgłoś błąd"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> 
                </>
            )}
            <BugReportModalBox  
                modalTitle="Opcje dostawy"
                sessionData={sessionData}
                openModal={isBugReportModalBoxOpen}
                setOpenModal={setIsBugReportModalBoxOpen}
            />
        </>
    )
}

// PropTypes
// Breadcrumbs.propTypes = {
//     cardDetails: PropTypes.object.isRequired,
// };