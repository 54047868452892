// src/helpers/serializeToJson.js
export const serializeToJson = (object) => {
    const seen = new WeakSet();
    
    // Funckja `replacer` zapobiega dodaniu do JSON-a obiektów, które już zostały wcześniej dodane, co zapobiega problemom z cyklicznymi odniesieniami
    const replacer = (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };

    try {
        return JSON.stringify(object, replacer);
    } catch (error) {
        // console.error('Błąd podczas serializacji JSON', error);
        return '{}'; // Zwróć pusty obiekt JSON jako zabezpieczenie
    }
};
